import { useRef } from "react";
import { useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { icosecahedronGeometry, sphereGeometry } from "../../Data/meshData";

const Player = ({ ref }) => {
  const lowerBody = useRef();
  const head = useRef();
  const ring = useRef();
  const ringB = useRef();
  const leftEye = useRef();
  const rightEye = useRef();

  const faceTexture = useTexture("../../images/Ananse.jpg");
  const eyesTexture = useTexture("../../images/manyPoints.jpg");

  useFrame((state, delta) => {
    const elapsedTime = state.clock.getElapsedTime();

    lowerBody.current.rotation.y =
      0.5 * Math.cos(elapsedTime * 0.8) + Math.PI * 0.5;
    head.current.rotation.y = 0.5 * Math.sin(elapsedTime * 0.8) - Math.PI * 0.5;
    leftEye.current.position.x = 0.15 * Math.sin(elapsedTime * 0.8) - 0.4;
    leftEye.current.rotation.y =
      0.8 * Math.sin(elapsedTime * 0.8) - Math.PI * 0.5;
    rightEye.current.position.x = 0.15 * Math.cos(elapsedTime * 0.8) + 0.4;
    rightEye.current.rotation.y =
      0.8 * Math.cos(elapsedTime * 0.8) - Math.PI * 0.5;

    ring.current.rotation.y = 0.2 * Math.sin(elapsedTime * 1.5);
    ringB.current.rotation.y = 0.2 * Math.cos(elapsedTime * 1.5);
  });

  return (
    <>
      <mesh
        ref={lowerBody}
        geometry={icosecahedronGeometry}
        position={[0, -0.2, 0]}
      >
        <meshBasicMaterial map={faceTexture} />
      </mesh>
      <mesh ref={head} geometry={sphereGeometry} position={[0, 0.6, 0]}>
        <meshBasicMaterial map={faceTexture} />
      </mesh>

      <mesh
        ref={leftEye}
        geometry={sphereGeometry}
        position={[0.55, 0.7, 0.518]}
        scale={[0.17, 0.17, 0.17]}
      >
        <meshBasicMaterial map={eyesTexture} />
      </mesh>
      <mesh
        ref={rightEye}
        geometry={sphereGeometry}
        position={[-0.5, 0.7, 0.518]}
        scale={[0.17, 0.17, 0.17]}
      >
        <meshBasicMaterial map={eyesTexture} />
      </mesh>

      {/* Rings Around PLayer */}
      <mesh
        ref={ring}
        position={[0, 0.1, -0.1]}
        rotation={[Math.PI * 0.5, Math.PI * 0.03, 0]}
      >
        <torusGeometry args={[0.65, 0.02, 16, 32]} />
        <meshStandardMaterial color={[0.6, 2.3, 3.0]} />
      </mesh>
      <mesh
        ref={ringB}
        position={[0, 0.1, -0.1]}
        rotation={[Math.PI * 0.5, -Math.PI * 0.03, 0]}
      >
        <torusGeometry args={[0.65, 0.02, 16, 32]} />
        <meshStandardMaterial color={[3.6, 2.3, 3.0]} />
      </mesh>
    </>
  );
};

export default Player;
