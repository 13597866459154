import React, { useEffect, useState } from "react";
import { useTexture, Html } from "@react-three/drei";
import { plane, clearGlass, glassMaterialB, glass } from "../../Data/meshData";
import { socialData } from "../../Data/socialData";

const SocialBoard = () => {
  const [music] = useState(() => new Audio("./sound/Love.mp3"));
  const [isPlaying, setIsPlaying] = useState(false); // State to track playback state

  const socialBoardTexture = useTexture(
    "./images/socials/SocialsBoardwMusic.jpg"
  );
  const playAudio = () => {
    music.volume = 0.3;
    music.loop = true;
    if (!isPlaying) {
      // Play only if not already playing
      try {
        music.play();
        setIsPlaying(true);
      } catch (error) {
        console.error("Error playing audio:", error);
      }
    }
    //music.play()
  };

  const pauseAudio = () => {
    if (isPlaying) {
      // Pause only if currently playing
      music.pause();
      setIsPlaying(false);
    }
  };

  return (
    <>
      {/* play Music button */}
      <mesh
        geometry={plane}
        scale={[2.5, 1.2, 1]}
        material={clearGlass}
        position={[3.7, 4.8, 11.99]}
        rotation={[0, Math.PI, 0]}
        onClick={isPlaying ? pauseAudio : playAudio}
      />
      <mesh
        geometry={plane}
        material={glass}
        scale={[9, 9, 9]}
        position={[-12, 8.5, 12]}
        rotation={[0, Math.PI, 0]}
      >
        <Html
          transform
          wrapperClass="webspace1"
          distanceFactor={1.2}
          position={[0, 0, 0]}
          scale={[0.3, 0.3, 0.3]}
          // occlude={occludeArray}
        >
           <iframe src="https://dukesportfolio.vercel.app/" /> 
           {/* <iframe src="https://www.youtube.com/embed/5bZcPYQHQbA" />  */}

        </Html>
      </mesh>
      {/* Social Media Signboard */}
      <mesh
        geometry={plane}
        scale={[9, 9, 9]}
        position={[9, 8.5, 12]}
        rotation={[0, Math.PI, 0]}
      >
        <meshBasicMaterial map={socialBoardTexture} />
      </mesh>
      {/* clickable layers on top of icon */}
      {socialData.map((social, index) => (
        <mesh
          key={index}
          geometry={plane}
          scale={[1.1, 2.1, 1]}
          material={clearGlass}
          position={social.position}
          rotation={[0, Math.PI, 0]}
          onClick={() => window.open(social.link)}
        />
      ))}
    </>
  );
};

export default SocialBoard;

// const stopAudio = () => {
//   if (isPlaying) { // Stop only if currently playing
//     music.pause(); // Pause first for a smoother transition
//     music.currentTime = 0; // Reset playback position to the beginning
//     setIsPlaying(false);
//   }
// };
