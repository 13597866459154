import * as THREE from "three";

//Geometries
export const boxGeometry = new THREE.BoxGeometry(1, 1, 1);
export const plane = new THREE.PlaneGeometry(1.78512, 1);
export const sphereGeometry = new THREE.SphereGeometry(0.5, 8, 8);
export const eyeGeometry = new THREE.SphereGeometry(0.2, 16, 16);
export const icosecahedronGeometry = new THREE.IcosahedronGeometry(0.4, 1);

//Materials
export const walkwayMaterial = new THREE.MeshBasicMaterial({
  color: "turquoise",
  //wireframe:true
});

export const myColor = new THREE.Color(1, 2, 6.2);
export const platformMaterial = new THREE.MeshBasicMaterial({ color: myColor });
export const pathColor = new THREE.Color(3, 1.4, 0.2);
export const roadMaterial = new THREE.MeshBasicMaterial({ color: pathColor });
export const glassMaterial = new THREE.MeshPhysicalMaterial({
  //color: 0xcccccc, // Optional: Set a faint color for the glass
  color: "violet",
  roughness: 0.1, // Adjust for desired reflectiveness (0 - smooth, 1 - rough)
  metalness: 0.0, // Glass is not metallic
  opacity: 1.0,
  transparent: true,
  transmission: 1.0,
  ior: 1.5,
  thickness: 0.1, // Adjust for material thickness
});
export const glassMaterialB = new THREE.MeshPhysicalMaterial({
  color: "aquamarine", // Optional: Set a faint color for the glass
  roughness: 0.1, // Adjust for desired reflectiveness (0 - smooth, 1 - rough)
  metalness: 0.0, // Glass is not metallic
  opacity: 1.0,
  transparent: true,
  transmission: 1.0,
  ior: 1.5,
  thickness: 0.1, // Adjust for material thickness
});
export const glassMaterialC = new THREE.MeshPhysicalMaterial({
  color: "salmon", // Optional: Set a faint color for the glass
  roughness: 0.1, // Adjust for desired reflectiveness (0 - smooth, 1 - rough)
  metalness: 0.0, // Glass is not metallic
  opacity: 1.0,
  transparent: true,
  transmission: 1.0,
  ior: 1.5,
  thickness: 0.1, // Adjust for material thickness
});
export const glass = new THREE.MeshPhysicalMaterial({
  color: "white", // Optional: Set a faint color for the glass
  roughness: 0.1, // Adjust for desired reflectiveness (0 - smooth, 1 - rough)
  metalness: 0.0, // Glass is not metallic
  opacity: 1.0,
  transparent: true,
  transmission: 1.0,
  ior: 1.5,
  thickness: 0.1, // Adjust for material thickness
});
export const clearGlass = new THREE.MeshPhysicalMaterial({
  roughness: 0.05, // Adjust for desired reflectiveness (0 - smooth, 1 - rough)
  metalness: 0.0, // Glass is not metallic
  opacity: 0.05,
  transparent: true,
  transmission: 1.0,
  ior: 1.5,
  thickness: 0.1, // Adjust for material thickness
});
