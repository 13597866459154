import { imageDB } from "./Config";
import { getDownloadURL, ref, listAll } from "firebase/storage";

//Async await function to get images from Fierbase Storage

const imageCollections = {
  verticalImages: "verticalImages",
  openGalleryImages: "openGalleryImages",
  longRoomGalleryImages: "longRoomGalleryImages",
  museum2Images: "museum2Images",
  roofTopImages: "roofTopImages",
};

const fetchImageUrls = async (collectionName) => {
  try {
    const imagesRef = ref(imageDB, collectionName);
    const result = await listAll(imagesRef);
    const promises = result.items.map(async (imageRef) => {
      return getDownloadURL(imageRef);
    });
    return Promise.all(promises);
  } catch (error) {
    console.error("Error fetching images:", error);
    return [];
  }
};

export const fetchVerticalImageUrls = () =>
  fetchImageUrls(imageCollections.verticalImages);
export const fetchOpenGalleryImageUrls = () =>
  fetchImageUrls(imageCollections.openGalleryImages);
export const fetchLongRoomGalleryImageUrls = () =>
  fetchImageUrls(imageCollections.longRoomGalleryImages);
export const fetchMuseum2GalleryImageUrls = () =>
  fetchImageUrls(imageCollections.museum2Images);
export const fetchroofTopImageUrls = () =>
  fetchImageUrls(imageCollections.roofTopImages);
