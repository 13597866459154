import { useRef } from "react";
import * as THREE from "three";
import { useGLTF, useTexture } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import { boxGeometry, roadMaterial } from "../../Data/meshData";
import { useFrame } from "@react-three/fiber";
import Transporters from "../Transporters";
import SocialBoard from "../SocialBoard";
import MuseumRigidBodies from "../MuseumRigidBodies";
import PlatformBoundaries from "../PlatformBoundaries";

const Room = () => {
  const spinningCube = useRef();
  //Building Model & Texture
  const { nodes } = useGLTF("./model/mergedMuseums.glb"); //import model
  const bakedTexture = useTexture("./model/baker.jpg");
  bakedTexture.flipY = false;

  //ANIMATIONS
  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    //Quaternion angles Calculated from Euler
    const quartenionRotationZ = new THREE.Quaternion();
    const eulerRotationZ = new THREE.Euler(0, 0, time * 0.1);
    quartenionRotationZ.setFromEuler(eulerRotationZ);
    if (spinningCube.current) {
      spinningCube.current.setNextKinematicRotation(quartenionRotationZ);
    }
  });

  return (
    <>
      <SocialBoard />
      {/* ENTIRE MODEL OF SPACE */}
      <mesh
        receiveShadow
        geometry={nodes.mergedMuseum.geometry}
        position={nodes.mergedMuseum.position}
      >
        <meshBasicMaterial map={bakedTexture} />
      </mesh>

      {/* CENTRAL YELLOW SPINNING CUBE (NOSE) */}
      <RigidBody
        ref={spinningCube}
        position={[0, 15, -129.53]}
        type="kinematicPosition"
      >
        <mesh
          geometry={boxGeometry}
          scale={[9, 9, 9]}
          material={roadMaterial}
        />
      </RigidBody>
      <MuseumRigidBodies />
      <PlatformBoundaries />
      <Transporters />
    </>
  );
};

export default Room;

useGLTF.preload("./model/mergedMuseums.glb");
