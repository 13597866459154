import * as THREE from "three";
import { useState, useEffect, useRef } from "react";
import {
  useMatcapTexture,
  Center,
  useTexture,
  Text3D,
} from "@react-three/drei";
import { plane } from "../../Data/meshData";
import { useFrame } from "@react-three/fiber";

//Placeholder object whilst site loads
const Placeholder = (props) => {
  const [matcapTexture] = useMatcapTexture("7877EE_D87FC5_75D9C7_1C78C0", 256);
  const placehoderTexture = useTexture("./images/MuseumRender.jpg");
  const [isSuspenseActive, setIsSuspenseActive] = useState(true);
  const ring = useRef();
  const ringB = useRef();
  const myText = useRef();

  useEffect(() => {
    //Centerize the pivot of the 3D TEXT
    myText.current.geometry.computeBoundingBox();
    const boundingBox = myText.current.geometry.boundingBox;
    const center = new THREE.Vector3();
    boundingBox.getCenter(center);
    myText.current.geometry.translate(-center.x, -center.y, -center.z);
    return () => {
      setIsSuspenseActive(true); // Reset state on unmount
    };
  }, []);

  useFrame((state, delta) => {
    const elapsedTime = state.clock.getElapsedTime();

    ring.current.rotation.y = 1.8 * Math.sin(elapsedTime * 0.6);
    ringB.current.rotation.y = -1.6 * Math.sin(elapsedTime * 0.8);
    myText.current.rotation.y = 0.6 * Math.sin(elapsedTime * 0.6);
  });

  return (
    <>
      {isSuspenseActive && (
        <>
          
            <mesh geometry={plane} scale={16}>
              <meshBasicMaterial map={placehoderTexture} />
            </mesh>
            
            <mesh
              ref={ring}
              position={[0, -1.1, 1.8]}
            >
              <torusGeometry args={[1, 0.05, 16, 32]} />
              <meshBasicMaterial color="pink" />
            </mesh>
            <mesh ref={ringB} position={[0, -1.1, 1.8]}>
              <torusGeometry args={[1.2, 0.05, 16, 32]} />
              <meshBasicMaterial color="turquoise" />
            </mesh>

            <Center position={[1.5, 1.5, 3]}>
              <Text3D
                ref={myText}
                font="./fonts/helvetiker_regular.typeface.json"
                size={0.4}
                height={0.2}
                curveSegments={12}
                bevelEnabled
                bevelThickness={0.02}
                bevelSize={0.02}
                bevelOffset={0}
                bevelSegments={5}
              >
                LOADING...
                <meshMatcapMaterial matcap={matcapTexture} />
              </Text3D>
            </Center>
        </>
      )}
    </>
  );
};
export default Placeholder;