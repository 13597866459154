import { RigidBody } from "@react-three/rapier";
import { platformBoundaryPositions, walkways } from "../../Data/positions";
import {
  glassMaterial,
  boxGeometry,
  walkwayMaterial,
  glassMaterialB,
  glassMaterialC,
} from "../../Data/meshData";
import { useRef } from "react";

const PlatformBoundaries = () => {
  const threeMaterials = [glassMaterial, glassMaterialB, glassMaterialC];
  const selectedMaterial =
    threeMaterials[Math.floor(Math.random() * threeMaterials.length)];

    const roadRef = useRef()

  const changeColor = (someStuff) => {
      console.log(someStuff);
      // someStuff.target.colliderObject.scale.set(2,2,2)
  };


  return (
    <>
      {/* boundaries of the platform */}
      {platformBoundaryPositions.map((platform, index) => (
        <RigidBody key={index} type="fixed" position={platform.position}>
          <mesh
            scale={platform.scale}
            geometry={boxGeometry}
            material={selectedMaterial}
          ></mesh>
        </RigidBody>
      ))}

      {/* Walkways - improvement - change color on collision */}
      {walkways.map((cover, index) => (
        <RigidBody
        ref={roadRef}
          key={index}
          type="fixed"
          position={cover.position}
          onCollisionEnter={changeColor}
          //onCollisionEnter={() => changeColor(roadRef)}
        >
          <mesh
            scale={cover.scale}
            geometry={boxGeometry}
            rotation={cover.rotation}
            material={walkwayMaterial}
          />
        </RigidBody>
      ))}
    </>
  );
};

export default PlatformBoundaries;
