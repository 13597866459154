import { useRef } from "react";
import { RigidBody } from "@react-three/rapier";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import {
  platformMaterial,
  boxGeometry,
  roadMaterial,
  glassMaterial,
} from "../../Data/meshData";

const Transporters = () => {
  //Museum Transporters
  const rightTransporter = useRef();
  const leftTransporter = useRef();
  const transporter3 = useRef();
  //First Space Elevators
  const firstFloorRight = useRef();
  const firstFloorLeft = useRef();
  const secondFloorRight = useRef();
  const secondFloorLeft = useRef();
  //Second Space Elevators
  const floatingRoomLift = useRef();
  const bridgeLiftRight = useRef();
  const bridgeLiftLeft = useRef();
  const storeyBuildingLlift = useRef();

  //PLATFORM ANIMATIONS
  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const angle = time * 0.5;
    //Y-Direction Movements
    const y = 8 * Math.sin(angle) + 8;
    const yB = 8 * Math.cos(angle) + 8;
    const yC = 4 * Math.cos(angle) + 8;
    const yBridge = 17.5 * Math.cos(angle) + 7.5;
    const yElevator = 20 * Math.sin(angle * 0.5) + 8;
    //Z-Direction Movements
    const z = 36 * Math.sin(angle * 0.5) - 55;
    const zCos = 36 * Math.cos(angle * 0.5) - 55;
    const zOther = 20 * Math.cos(angle * 1.5) - 55;

    if (rightTransporter.current) {
      rightTransporter.current.setNextKinematicTranslation({
        x: 25,
        y: -0.4,
        z: -18.5 + z,
      });
    }
    if (leftTransporter.current) {
      leftTransporter.current.setNextKinematicTranslation({
        x: -25,
        y: -0.4,
        z: -18.5 + zCos,
      });
    }
    if (transporter3.current) {
      transporter3.current.setNextKinematicTranslation({
        x: -25,
        y: -0.4,
        z: -190.5 + zOther,
      });
    }
    if (firstFloorRight.current) {
      firstFloorRight.current.setNextKinematicTranslation({
        x: 0,
        y: y,
        z: 0,
      });
    }
    if (firstFloorLeft.current) {
      firstFloorLeft.current.setNextKinematicTranslation({
        x: 0,
        y: yB,
        z: 0,
      });
    }
    if (secondFloorRight.current) {
      secondFloorRight.current.setNextKinematicTranslation({
        x: 0,
        y: y,
        z: 0,
      });
    }
    if (secondFloorLeft.current) {
      secondFloorLeft.current.setNextKinematicTranslation({
        x: 0,
        y: yB,
        z: 0,
      });
    }
    if (floatingRoomLift.current) {
      floatingRoomLift.current.setNextKinematicTranslation({
        x: 0,
        y: yC,
        z: 0,
      });
    }
    if (bridgeLiftRight.current) {
      bridgeLiftRight.current.setNextKinematicTranslation({
        x: 0,
        y: yBridge,
        z: 0,
      });
    }
    if (bridgeLiftLeft.current) {
      bridgeLiftLeft.current.setNextKinematicTranslation({
        x: 0,
        y: yBridge,
        z: 0,
      });
    }
    if (storeyBuildingLlift.current) {
      storeyBuildingLlift.current.setNextKinematicTranslation({
        x: 0,
        y: yElevator,
        z: 0,
      });
    }
  });

  return (
    <>
      {/* PLATFORMS */}
      <RigidBody ref={rightTransporter} type="kinematicPosition">
        <mesh
          scale={[4, 0.2, 8]}
          geometry={boxGeometry}
          material={platformMaterial}
          position={[0, 0.7, 0]}
        />
        <mesh
          position={[2, 2.8, 0]}
          scale={[0.2, 4, 8]}
          geometry={boxGeometry}
          material={roadMaterial}
        />
        <mesh
          position={[-2, 2.8, 0]}
          scale={[0.2, 4, 8]}
          geometry={boxGeometry}
          material={roadMaterial}
        />
        <mesh
          position={[0, 1, -4]}
          scale={[4, 0.5, 0.2]}
          geometry={boxGeometry}
          material={glassMaterial}
        />
        <mesh
          position={[0, 1, 4]}
          scale={[4, 0.5, 0.2]}
          geometry={boxGeometry}
          material={glassMaterial}
        />
      </RigidBody>
      <RigidBody ref={leftTransporter} type="kinematicPosition">
        <mesh
          scale={[4, 0.2, 8]}
          geometry={boxGeometry}
          material={platformMaterial}
          position={[0, 0.7, 0]}
        />
        <mesh
          position={[2, 2.8, 0]}
          scale={[0.2, 4, 8]}
          geometry={boxGeometry}
          material={roadMaterial}
        />
        <mesh
          position={[-2, 2.8, 0]}
          scale={[0.2, 4, 8]}
          geometry={boxGeometry}
          material={roadMaterial}
        />
        <mesh
          position={[0, 1, -4]}
          scale={[4, 0.5, 0.2]}
          geometry={boxGeometry}
          material={glassMaterial}
        />
        <mesh
          position={[0, 1, 4]}
          scale={[4, 0.5, 0.2]}
          geometry={boxGeometry}
          material={glassMaterial}
        />
      </RigidBody>
      <RigidBody ref={transporter3} type="kinematicPosition">
        <mesh
          scale={[10, 0.3, 15]}
          geometry={boxGeometry}
          material={platformMaterial}
          position={[25, 0.5, 0]}
        />
        <mesh
          position={[30.8, 2.45, 0]}
          scale={[0.2, 4, 15]}
          geometry={boxGeometry}
          material={roadMaterial}
          rotation={[0, 0, -Math.PI * 0.15]}
        />
        <mesh
          position={[19.2, 2.45, 0]}
          scale={[0.2, 4, 15]}
          geometry={boxGeometry}
          material={roadMaterial}
          rotation={[0, 0, Math.PI * 0.15]}
        />
        <mesh
          position={[25, 1.25, -7.4]}
          scale={[9.5, 1, 0.2]}
          geometry={boxGeometry}
          material={glassMaterial}
        />
        <mesh
          position={[25, 1.25, 7.4]}
          scale={[9.5, 1, 0.2]}
          geometry={boxGeometry}
          material={glassMaterial}
        />
      </RigidBody>
      <RigidBody ref={firstFloorRight} type="kinematicPosition">
        <mesh
          scale={[8, 0.3, 8]}
          geometry={boxGeometry}
          material={platformMaterial}
          position={[35, 0.5, -138]}
        />
      </RigidBody>
      <RigidBody ref={firstFloorLeft} type="kinematicPosition">
        <mesh
          scale={[8, 0.3, 8]}
          geometry={boxGeometry}
          material={platformMaterial}
          position={[-35, 0.5, -138]}
        />
      </RigidBody>
      <RigidBody ref={secondFloorRight} type="kinematicPosition">
        <mesh
          scale={[5, 0.3, 5]}
          geometry={boxGeometry}
          material={platformMaterial}
          position={[19, 17, -125]}
        />
      </RigidBody>
      <RigidBody ref={secondFloorLeft} type="kinematicPosition">
        <mesh
          scale={[5, 0.3, 5]}
          geometry={boxGeometry}
          material={platformMaterial}
          position={[-19, 17, -125]}
        />
      </RigidBody>
      <RigidBody ref={floatingRoomLift} type="kinematicPosition">
        <mesh
          scale={[15, 0.3, 4]}
          geometry={boxGeometry}
          material={platformMaterial}
          position={[0, 23.5, -274]}
        />
      </RigidBody>
      <RigidBody ref={bridgeLiftRight} type="kinematicPosition">
        <mesh
          scale={[6, 0.3, 6]}
          geometry={boxGeometry}
          material={platformMaterial}
          position={[51, 10, -307.3]}
        />
      </RigidBody>
      <RigidBody ref={bridgeLiftLeft} type="kinematicPosition">
        <mesh
          scale={[6, 0.3, 6]}
          geometry={boxGeometry}
          material={platformMaterial}
          position={[-51, 10, -307.3]}
        />
      </RigidBody>
      <RigidBody ref={storeyBuildingLlift} type="kinematicPosition">
        <mesh
          scale={[6, 0.3, 6]}
          geometry={boxGeometry}
          material={platformMaterial}
          position={[0, 12.5, -341.5]}
        />
      </RigidBody>
    </>
  );
};
export default Transporters;
