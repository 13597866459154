import "./CSS/style.css";
import ReactDOM from "react-dom/client";
import { Canvas } from "@react-three/fiber";
import Experience from "./Components/Experience/index.jsx";
import { StrictMode, Suspense } from "react";
import Controlsinterface from "./ControlsInterface/index.jsx";
import Placeholder from "./Components/Placeholder/index.jsx";

// import { Leva } from "leva";

const root = ReactDOM.createRoot(document.querySelector("#root"));


root.render(
  <>
    <StrictMode>
      {/* <Leva collapsed /> */}
      <Canvas
        shadows
        //flat // corrects the tonemapping to get same model colors from blender
        camera={{
          fov: 80,
          near: 0.1,
          far: 500,
          position: [0, 1, 6],
        }}
      >
        <Suspense fallback={<Placeholder position-z={-20} scale={[1, 1, 1]} />}>
          <Experience />
        </Suspense>
      </Canvas>
      <Controlsinterface />
    </StrictMode>
  </>
);
