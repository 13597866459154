import { glass, glassMaterial, glassMaterialB } from "./meshData";

/**
 * Keyboard control preset
 */
export const keyboardMap = [
  { name: "forward", keys: ["ArrowUp", "KeyW"] },
  { name: "backward", keys: ["ArrowDown", "KeyS"] },
  { name: "leftward", keys: ["ArrowLeft", "KeyA"] },
  { name: "rightward", keys: ["ArrowRight", "KeyD"] },
  { name: "jump", keys: ["Space"] },
  { name: "run", keys: ["Shift"] },
];

export const museumGlassBodies = [
  //RightHallways
  {
    position: [17.15, 20.6, -169],
    scale: [0.1, 8.3, 70.4],
    material: glass,
  },
  {
    position: [6.99, 20.6, -169],
    scale: [0.1, 8.3, 70.4],
    material: glass,
  },
  {
    position: [12.0, 16.3, -169],
    scale: [10, 0.3, 70.4],
    material: glassMaterialB,
  },
  //Left hallways
  {
    position: [-17.23, 20.6, -169],
    scale: [0.1, 8.3, 70.4],
    material: glass,
  },
  {
    position: [-7.06, 20.6, -169],
    scale: [0.1, 8.3, 70.4],
    material: glass,
  },
  {
    position: [-12.15, 16.3, -169],
    scale: [10, 0.3, 70.4],
    material: glassMaterialB,
  },
  //FLOATING ROOM
  {
    position: [0, 27.6, -286.35],
    scale: [18.9, 0.02, 18.9],
    material: glassMaterial,
  },
  {
    position: [0, 35, -286.35],
    scale: [18.9, 0.02, 18.9],
    material: glassMaterial,
  },
  {
    position: [9.5, 31.3, -286.35],
    scale: [0.02, 7.4, 18.9],
    material: glass,
  },
  {
    position: [-9.5, 31.3, -286.35],
    scale: [0.02, 7.4, 18.9],
    material: glass,
  },
  //LEFTSIDE ROOM BELOW
  {
    position: [-37.5, 7.8, -299.35],
    scale: [0.02, 15.5, 18.5],
    material: glassMaterialB,
  },
  {
    position: [-24.3, 7.8, -290.2],
    scale: [26.2, 15.5, 0.02],
    material: glassMaterialB,
  },
  {
    position: [-24.3, 7.8, -308.8],
    scale: [26.2, 15.5, 0.02],
    material: glassMaterialB,
  },
  //RIGHTSIDE ROOM BELOW
  {
    position: [37.5, 7.8, -299.35],
    scale: [0.02, 15.5, 18.5],
    material: glassMaterialB,
  },
  {
    position: [24.3, 7.8, -290.2],
    scale: [26.2, 15.5, 0.02],
    material: glassMaterialB,
  },
  {
    position: [24.3, 7.8, -308.8],
    scale: [26.2, 15.5, 0.02],
    material: glassMaterialB,
  },
  // BOX STEPPERS
  {
    position: [21.25, 33.7, -315],
    scale: [6.2, 6.1, 6.2],
    material: glass,
  },
  {
    position: [16.6, 34.6, -323.5],
    scale: [6.2, 6.2, 6.2],
    material: glass,
  },
  {
    position: [-21.25, 33.7, -315],
    scale: [6.2, 6.1, 6.2],
    material: glass,
  },
  {
    position: [-16.6, 34.6, -323.5],
    scale: [6.2, 6.2, 6.2],
    material: glass,
  },
];

//MESHLESS RIGID BODIES WITH COLLIDERS
export const museumRigidBodiesInfo = [
  //First Gallery Space Floor
  { colliderArgs: [69.5, 0.055, 57.2], position: [0, -0.025, -167] },
  { colliderArgs: [4.1, 8, 1.4], position: [20.55, 8, -126.5] },
  {
    colliderArgs: [4.1, 8, 1.4],
    position: [36.4, 8, -126.5],
    rotation: [0, Math.PI * 0.5, 0],
  },
  { colliderArgs: [4.1, 8, 1.4], position: [-20.55, 8, -126.5] },
  {
    colliderArgs: [4.1, 8, 1.4],
    position: [-36.4, 8, -126.5],
    rotation: [0, Math.PI * 0.5, 0],
  },
  {
    colliderArgs: [4.1, 8, 1.4],
    position: [-36.4, 8, -126.5],
    rotation: [0, Math.PI * 0.5, 0],
  },
  //Front Platforms
  { colliderArgs: [15.4, 0.3, 5.7], position: [22.5, 16.2, -128.1] },
  { colliderArgs: [15.4, 0.3, 5.7], position: [-22.5, 16.2, -128.1] },
  //LONG SIDE PLANKS
  { colliderArgs: [6.9, 0.3, 35.2], position: [24.1, 16.3, -168.8] },
  { colliderArgs: [6.9, 0.3, 35.2], position: [-24.1, 16.3, -168.9] },
  //Left Hallway Door
  { colliderArgs: [4.9, 2, 0.1], position: [-12.1, 22.5, -133.8] },
  { colliderArgs: [1.8, 1.95, 0.1], position: [-15.25, 18.5, -133.8] },
  { colliderArgs: [1.8, 1.95, 0.1], position: [-8.9, 18.5, -133.8] },
  //Right Hallway Door
  { colliderArgs: [4.9, 2, 0.1], position: [12.1, 22.5, -133.8] },
  { colliderArgs: [1.8, 1.95, 0.1], position: [15.25, 18.5, -133.8] },
  { colliderArgs: [1.8, 1.95, 0.1], position: [8.9, 18.5, -133.8] },
  //Ceiling
  { colliderArgs: [22.4, 0.6, 35.3], position: [0, 25.45, -165.2] },
  { colliderArgs: [0.4, 3.7, 40], position: [22, 29.8, -169.9] },
  { colliderArgs: [0.4, 3.7, 40], position: [-22, 29.8, -169.9] },
  //BACK PILLARS
  { colliderArgs: [7, 8, 0.35], position: [24.2, 8, -203.6] },
  { colliderArgs: [7, 8, 0.35], position: [-24.2, 8, -203.6] },
  //MIDDLE PILLARS
  {
    colliderArgs: [4, 8, 0.35],
    position: [-12, 8, -183.6],
    rotation: [0, Math.PI * 0.25, 0],
  },
  {
    colliderArgs: [4, 8, 0.35],
    position: [12, 8, -183.6],
    rotation: [0, -Math.PI * 0.25, 0],
  },
  {
    colliderArgs: [4, 8, 0.35],
    position: [12, 8, -153.75],
    rotation: [0, Math.PI * 0.25, 0],
  },
  {
    colliderArgs: [4, 8, 0.35],
    position: [-12, 8, -153.75],
    rotation: [0, -Math.PI * 0.25, 0],
  },
  //DOWNSTAIRS OPEN GALLERY
  { colliderArgs: [0.25, 3.6, 6.1], position: [9.5, 3.8, -129.35] },
  { colliderArgs: [0.25, 3.6, 6.1], position: [-9.58, 3.8, -129.35] },
  { colliderArgs: [6.1, 3.6, 0.25], position: [0, 3.8, -136.55] },
  { colliderArgs: [6.1, 3.6, 0.25], position: [0, 3.8, -121.9] },
  //UPSTAIRS OPEN GALLERY
  { colliderArgs: [0.25, 3.6, 6.1], position: [9.5, 29.9, -165.35] },
  { colliderArgs: [0.25, 3.6, 6.1], position: [-9.58, 29.9, -165.35] },
  { colliderArgs: [6.1, 3.6, 0.25], position: [0, 29.9, -172.55] },
  { colliderArgs: [6.1, 3.6, 0.25], position: [0, 29.9, -157.9] },
  //SECOND GALLERY SPACE COLLIDERS
  { colliderArgs: [16.5, 0.6, 2.9], position: [30.8, 34.2, -307.35] },
  { colliderArgs: [16.5, 0.6, 2.9], position: [-30.8, 34.2, -307.35] },
  { colliderArgs: [0.5, 17.8, 2.5], position: [-47.2, 17.05, -307.35] },
  { colliderArgs: [0.5, 17.8, 2.5], position: [47.2, 17.05, -307.35] },
  //ROOM
  { colliderArgs: [14.2, 0.1, 5.82], position: [0, 34.95, -307.35] },
  { colliderArgs: [14.2, 3.9, 0.1], position: [0, 42.2, -301.35] },
  { colliderArgs: [6.3, 1.65, 0.1], position: [-7.9, 36.6, -301.35] },
  { colliderArgs: [6.3, 1.65, 0.1], position: [7.9, 36.6, -301.35] },
  { colliderArgs: [14.2, 3.9, 0.1], position: [0, 42.2, -313.25] },
  { colliderArgs: [6.3, 1.65, 0.1], position: [-7.9, 36.6, -313.25] },
  { colliderArgs: [6.3, 1.65, 0.1], position: [7.9, 36.6, -313.25] },
  { colliderArgs: [0.22, 3.9, 5.7], position: [-14.1, 42.2, -307.25] },
  { colliderArgs: [0.22, 1.65, 2.1], position: [-14.1, 36.6, -303.5] },
  { colliderArgs: [0.22, 1.65, 2.1], position: [-14.1, 36.6, -311] },
  { colliderArgs: [0.22, 3.9, 5.7], position: [14, 42.2, -307.25] },
  { colliderArgs: [0.22, 1.65, 2.1], position: [14, 36.6, -303.5] },
  { colliderArgs: [0.22, 1.65, 2.1], position: [14, 36.6, -311] },
  //Storey Building
  { colliderArgs: [14.2, 17, 0.2], position: [0, 22.2, -327.05] },
  { colliderArgs: [6.24, 2.6, 0.2], position: [-7.95, 2.5, -327.05] },
  { colliderArgs: [6.24, 2.6, 0.2], position: [7.95, 2.5, -327.05] },
  { colliderArgs: [1.7, 0.3, 0.2], position: [0, 0.23, -327.05] },
  //BACKWALL
  { colliderArgs: [14.2, 19.8, 0.1], position: [0, 19.6, -355.7] },
  { colliderArgs: [0.1, 19.8, 14.2], position: [14.15, 19.6, -341.5] },
  { colliderArgs: [0.1, 19.8, 14.2], position: [-14.15, 19.6, -341.5] },
  //TOP FLOOR
  { colliderArgs: [13.9, 0.1, 4.7], position: [0, 39.4, -332.4] },
  { colliderArgs: [13.9, 0.1, 4.7], position: [0, 39.4, -350.6] },
  { colliderArgs: [4.5, 0.1, 4.4], position: [9, 39.4, -341.5] },
  { colliderArgs: [4.5, 0.1, 4.4], position: [-9, 39.4, -341.5] },
  // FOURTH FLOOR
  { colliderArgs: [13.9, 0.1, 4.7], position: [0, 32.05, -332.4] },
  { colliderArgs: [13.9, 0.1, 4.7], position: [0, 32.05, -350.6] },
  { colliderArgs: [4.5, 0.1, 4.4], position: [9, 32.05, -341.5] },
  { colliderArgs: [4.5, 0.1, 4.4], position: [-9, 32.05, -341.5] },
  // THIRD FLOOR
  { colliderArgs: [13.9, 0.1, 4.7], position: [0, 24.05, -332.4] },
  { colliderArgs: [13.9, 0.1, 4.7], position: [0, 24.05, -350.6] },
  { colliderArgs: [4.5, 0.1, 4.4], position: [9, 24.05, -341.5] },
  { colliderArgs: [4.5, 0.1, 4.4], position: [-9, 24.05, -341.5] },
  // SECOND FLOOR
  { colliderArgs: [13.9, 0.1, 4.7], position: [0, 16.05, -332.4] },
  { colliderArgs: [13.9, 0.1, 4.7], position: [0, 16.05, -350.6] },
  { colliderArgs: [4.5, 0.1, 4.4], position: [9, 16.05, -341.5] },
  { colliderArgs: [4.5, 0.1, 4.4], position: [-9, 16.05, -341.5] },
  // FIRST FLOOR
  { colliderArgs: [13.9, 0.1, 4.7], position: [0, 8.05, -332.4] },
  { colliderArgs: [13.9, 0.1, 4.7], position: [0, 8.05, -350.6] },
  { colliderArgs: [4.5, 0.1, 4.4], position: [9, 8.05, -341.5] },
  { colliderArgs: [4.5, 0.1, 4.4], position: [-9, 8.05, -341.5] },
  //ROOFTOP PAINTINGS
  { colliderArgs: [10.1, 6, 0.25], position: [-0.85, 45.7, -355.2] },
  { colliderArgs: [0.25, 6, 10.1], position: [-13.75, 45.7, -341.2] },
  { colliderArgs: [0.25, 6, 10.1], position: [13.75, 45.7, -341.2] },
  //Entire Floor
  { colliderArgs: [55.25, 0.3, 45.6], position: [0, -0.1, -314.1] },
];

export const platformBoundaryPositions = [
  //Floor
  { position: [0, -0.133, -6], scale: [60, 0.2, 60.5] },
  //Long Side Walls
  { position: [-30.2, 1.6, -6], scale: [0.5, 3, 60] },
  { position: [30.2, 1.6, -6], scale: [0.5, 3, 60] },
  //backwall
  { position: [0, 1.6, 24.5], scale: [60, 3, 0.5] },
  //Front wall Barriers
  { position: [-12.5, 1.6, -36.4], scale: [20, 3, 0.5] },
  { position: [12.5, 1.6, -36.4], scale: [20, 3, 0.5] },
  { position: [-28.5, 1.6, -36.4], scale: [2.8, 3, 0.5] },
  { position: [28.5, 1.6, -36.4], scale: [2.8, 3, 0.5] },
  //First Galler Walls
  { position: [49, 3.7, -109.5], scale: [40, 7, 0.5] },
  { position: [-49, 3.7, -109.5], scale: [40, 7, 0.5] },
  { position: [12.5, 3.7, -109.5], scale: [19, 7, 0.5] },
  { position: [-12.5, 3.7, -109.5], scale: [19, 7, 0.5] },
  { position: [69.3, 3.7, -166.8], scale: [0.5, 7, 113.9] },
  { position: [-69.3, 3.7, -166.8], scale: [0.5, 7, 113.9] },
  { position: [32, 3.3, -268.5], scale: [46, 7, 0.5] },
  { position: [39, 3.7, -224.5], scale: [60, 7, 0.5] },
  //Second Gallery Walls
  { position: [55.3, 3.3, -314], scale: [0.5, 7, 90] },
  { position: [-55.3, 3.3, -314], scale: [0.5, 7, 90] },
  { position: [0, 3.3, -359.5], scale: [110, 7, 0.5] },
  { position: [-32, 3.3, -268.5], scale: [46, 7, 0.5] },
  { position: [-39, 3.7, -224.5], scale: [60, 7, 0.5] },
];

//Position of cubicle glass coverings
export const walkways = [
  //FIrst Walking floating road platforms
  //SMALL CUBICLES
  { position: [0, 0.2, -40.7], scale: [6, 0.05, 6.5], rotation: [0, 0, 0] },
  { position: [0, 0.4, -47.7], scale: [6, 0.05, 6.5], rotation: [0, 0, 0] },
  { position: [0, 0.6, -54.7], scale: [6, 0.05, 6.5], rotation: [0, 0, 0] },
  { position: [0, 0, -61.7], scale: [6, 0.05, 6.5], rotation: [0, 0, 0] },
  { position: [0, 0.6, -68.7], scale: [6, 0.05, 6.5], rotation: [0, 0, 0] },
  { position: [0, -0.2, -75.7], scale: [6, 0.05, 6.5], rotation: [0, 0, 0] },
  { position: [0, 0.2, -82.7], scale: [6, 0.05, 6.5], rotation: [0, 0, 0] },
  { position: [0, 0.7, -89.7], scale: [6, 0.05, 6.5], rotation: [0, 0, 0] },
  { position: [0, -0.4, -96.7], scale: [6, 0.05, 6.5], rotation: [0, 0, 0] },
  { position: [0, 0.2, -103.7], scale: [6, 0.05, 6.5], rotation: [0, 0, 0] },

  //Extension to 2nd museum
  { position: [0, 27.6, -204], scale: [8, 0.05, 8], rotation: [0, 0, 0] },
  { position: [0, 28.6, -212], scale: [8, 0.05, 8], rotation: [0, 0, 0] },
  { position: [0, 29.6, -220], scale: [8, 0.05, 8], rotation: [0, 0, 0] },
  { position: [0, 30.6, -228], scale: [8, 0.05, 8], rotation: [0, 0, 0] },
  { position: [0, 31.6, -236], scale: [8, 0.05, 8], rotation: [0, 0, 0] },
  { position: [0, 32.6, -244], scale: [8, 0.05, 8], rotation: [0, 0, 0] },
  { position: [0, 33.6, -252], scale: [8, 0.05, 8], rotation: [0, 0, 0] },
  { position: [0, 34.6, -260], scale: [8, 0.05, 8], rotation: [0, 0, 0] },
  { position: [0, 35.6, -268], scale: [8, 0.05, 8], rotation: [0, 0, 0] },
  { position: [0, 35.2, -298.5], scale: [5, 0.05, 5], rotation: [0, 0, 0] },
  {
    position: [0, 37.3, -320.1],
    scale: [5, 0.05, 14],
    rotation: [Math.PI * 0.1, 0, 0],
  },
];
