import React from "react";
import { boxGeometry } from "../../Data/meshData";
import { RigidBody, CuboidCollider } from "@react-three/rapier";
import { museumRigidBodiesInfo, museumGlassBodies } from "../../Data/positions";

const MuseumRigidBodies = () => {
  return (
    <>
      {museumRigidBodiesInfo.map((element, index) => (
        <RigidBody type="fixed" key={index}>
          <CuboidCollider
            args={element.colliderArgs}
            position={element.position}
            rotation={element.rotation && element.rotation}
          />
        </RigidBody>
      ))}
      {/* Museum Glass Mesh Rigid Bodies */}
      {museumGlassBodies.map((element, index) => (
        <RigidBody type="fixed" key={index} position={element.position}>
          <mesh
            scale={element.scale}
            geometry={boxGeometry}
            material={element.material}
          ></mesh>
        </RigidBody>
      ))}
    </>
  );
};

export default MuseumRigidBodies;
