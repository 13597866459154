// import { Perf } from "r3f-perf";
import { useRef, useEffect, useState } from "react";
import {
  Stars,
  Environment,
  Lightformer,
  KeyboardControls,
  //OrbitControls,
  useTexture,
} from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { Physics } from "@react-three/rapier";
import Ecctrl from "ecctrl";
import { Bloom, EffectComposer } from "@react-three/postprocessing";
import Player from "../Player";
import Room from "../Room";
import useMyGame from "../../stores/useMyGame";
import { keyboardMap } from "../../Data/positions";
import {
  fetchVerticalImageUrls,
  fetchOpenGalleryImageUrls,
  fetchLongRoomGalleryImageUrls,
  fetchMuseum2GalleryImageUrls,
  fetchroofTopImageUrls,
} from "../../FirebaseImageUpload/ImageService";
import PictureFrames from "../PictureFrames";
import { shuffleArray } from "../../Functions/shuffleArray";

const Experience = () => {

  const [allArtworks, setAllArtworks] = useState({
    verticalArtworks: [],
    openGalleryArtworks: [],
    longRoomGalleryArtworks: [],
    museum2GalleryArtworks: [],
    roofTopGalleryArtworks: [],
  });
  // SET TEXTURES
  const verticalArtTextures = useTexture(allArtworks.verticalArtworks);
  const openGalleryArtTextures = useTexture(allArtworks.openGalleryArtworks);
  const longRoomGalleryArtTextures = useTexture(
    allArtworks.longRoomGalleryArtworks
  );
  const museum2GalleryArtTextures = useTexture(
    allArtworks.museum2GalleryArtworks
  );
  const roofTopGalleryArtTextures = useTexture(
    allArtworks.roofTopGalleryArtworks
  );

  const restart = useMyGame((state) => state.restart);
  const start = useMyGame((state) => state.start);

  const playerRef = useRef();

  useFrame((state) => {
    //const elapsedTime = state.clock.getElapsedTime();
    //reset player to start position if it falls
    if (playerRef.current) {
      const playerPosition = playerRef.current.translation();
      if (playerPosition.y < -4) {
        restart();
      }
    }
  });

  useEffect(() => {
    const unsubscribeReset = useMyGame.subscribe(
      (state) => state.phase,
      (phase) => {
        if (phase === "ready") reset();
      }
    );
    //FETCH ARTWORKS & update state with them
    const fetchArtworks = async () => {
      try {
        const [
          verticalImageUrls,
          openGalleryImageUrls,
          longRoomGalleryImageUrls,
          museum2GalleryImageUrls,
          roofTopGalleryImageUrls,
        ] = await Promise.all([
          fetchVerticalImageUrls(),
          fetchOpenGalleryImageUrls(),
          fetchLongRoomGalleryImageUrls(),
          fetchMuseum2GalleryImageUrls(),
          fetchroofTopImageUrls(),
        ]);

        setAllArtworks((prevState) => ({
          ...prevState,
          verticalArtworks: shuffleArray(verticalImageUrls),
          openGalleryArtworks: shuffleArray(openGalleryImageUrls),
          longRoomGalleryArtworks: shuffleArray(longRoomGalleryImageUrls),
          museum2GalleryArtworks: shuffleArray(museum2GalleryImageUrls),
          roofTopGalleryArtworks: roofTopGalleryImageUrls,
        }));
      } catch (error) {
        console.error("Error fetching image URLs:", error);
      }
    };

    fetchArtworks();

    // Cleanup to unsubscribe
    return () => {
      unsubscribeReset();
    };
  }, []);

  //reset ball position when it falls below space
  const reset = () => {
    playerRef.current.setTranslation({ x: 0, y: 1.2929608821868896, z: 0 });
    playerRef.current.setLinvel({ x: 0, y: 0, z: 0 });
    playerRef.current.setAngvel({ x: 0, y: 0, z: 0 });
    start(); //set phase to playing
  };

  return (
    <>
      {allArtworks.verticalArtworks.length > 0 && (
        <>
          <color args={["#030202"]} attach="background" />
          {/* <Perf position="top-left" /> */}
          {/* <OrbitControls makeDefault /> */}
          <Environment preset="dawn" resolution={32}>
            <Lightformer
              position-z={-5}
              scale={10}
              color="orange"
              intensity={4}
              form="ring"
            />
          </Environment>
          <Physics debug={false}>
            <KeyboardControls map={keyboardMap}>
              <Ecctrl
                ref={playerRef}
                floatHeight={0.1}
                maxVelLimit={5}
                jumpVel={5}
                capsuleRadius={0.65}
                capsuleHalfHeight={0.205}
              >
                <Player />
              </Ecctrl>
            </KeyboardControls>
            <PictureFrames
              textures={verticalArtTextures}
              openGalleryTextures={openGalleryArtTextures}
              longRoomGalleryArtTextures={longRoomGalleryArtTextures}
              museum2GalleryArtTextures={museum2GalleryArtTextures}
              roofTopGalleryArtTextures={roofTopGalleryArtTextures}
            />
            <Room />
            <Stars
              radius={170}
              depth={20}
              count={5000}
              factor={4}
              saturation={0}
              fade
              speed={1}
            />
            <EffectComposer>
              <Bloom mipmapBlur intensity={0.7} luminanceThreshold={1.1} />
            </EffectComposer>
          </Physics>
        </>
      )}
    </>
  );
};

export default Experience;

