import React from "react";
import { useGLTF } from "@react-three/drei";

const PictureFrames = ({
  textures,
  openGalleryTextures,
  longRoomGalleryArtTextures,
  museum2GalleryArtTextures,
  roofTopGalleryArtTextures,
}) => {
  const frameData = [
    {
      name: "vertical",
      url: "./model/verticalArtFrames.glb",
      textures: textures,
    },
    {
      name: "openGallery",
      url: "./model/openGalleryArtFrames.glb",
      textures: openGalleryTextures,
    },
    {
      name: "longroomGallery",
      url: "./model/longroomArtFrames.glb",
      textures: longRoomGalleryArtTextures,
    },
    {
      name: "museum2Gallery",
      url: "./model/museum2ArtFrames.glb",
      textures: museum2GalleryArtTextures,
    },
    {
      name: "roofTopGallery",
      url: "./model/roofTopArtFrames.glb",
      textures: roofTopGalleryArtTextures,
    },
  ];

  return (
    <>
      {frameData.map((frame) => {
        const { nodes } = useGLTF(frame.url);
        const filteredNodes = Object.values(nodes).filter(
          (node) => node.name !== "Scene"
        );

        return filteredNodes.map((child, index) => (
          <mesh
            key={`${frame.name}-${child.uuid}`} // Combine frame name and uuid
            geometry={child.geometry}
            position={child.position}
            scale={child.scale}
            rotation={child.rotation}
          >
            <meshBasicMaterial map={frame.textures[index]} />
          </mesh>
        ));
      })}
    </>
  );
};

export default PictureFrames;

useGLTF.preload("./model/verticalArtFrames.glb");
useGLTF.preload("./model/openGalleryArtFrames.glb");
useGLTF.preload("./model/longroomArtFrames.glb");
useGLTF.preload("./model/museum2ArtFrames.glb");
useGLTF.preload("./model/roofTopArtFrames.glb");
