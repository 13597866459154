export const socialData = [
  {
    name: "linkedin",
    position: [15.55, 8.7, 11.99],
    link: "https://www.linkedin.com/in/dyeboah/",
  },
  {
    name: "instagram",
    position: [13, 8.7, 11.99],
    texturePath: "./images/socials/instagram_w.jpg",
    link: "https://www.instagram.com/afrospiritart/?hl=en",
  },
  {
    name: "redbubble",
    position: [10.38, 8.66, 11.99],
    texturePath: "./images/socials/redbubble_.jpg",
    link: "https://www.redbubble.com/people/afro-spirit-art/shop?artistUserName=afro-spirit-art&iaCode=u-backpack",
  },
  {
    name: "youtube",
    position: [7.81, 8.66, 11.99],
    texturePath: "./images/socials/youtubeW.jpg",
    link: "https://www.youtube.com/@afrospiritart/shorts",
  },
  {
    name: "etsy",
    position: [5.2, 8.73, 11.99],
    texturePath: "./images/socials/etsy.jpg",
    link: "https://www.etsy.com/shop/AfroSpiritArt",
  },
  {
    name: "twitter",
    position: [2.5, 8.73, 11.99],
    texturePath: "./images/socials/twitter_X.jpg",
    link: "https://twitter.com/afrospiritart/status/1553523273811509250",
  },
];
